import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { UserData } from "@sportyano/core/models/authentications/login-data.model";
import { IResponse } from "@sportyano/core/models/response/response.interface";
import {
	USER_TYPE,
	USERS_TYPE,
	UsersType,
	UserType,
} from "@sportyano/core/models/user-type/user-type";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { filter, map, Observable, of, switchMap } from "rxjs";
import { tap } from "rxjs/internal/operators/tap";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class AccountService {
	private _HttpClient: HttpClient = inject(HttpClient);
	private _authService: AuthService = inject(AuthService);

	public getAccountData(
		userType: UsersType
	  ): Observable<Omit<IResponse<UserData>, "data">> {
		return of(this._userTypeToUsersType(userType)).pipe(
		  filter((f) => !!f),
		  switchMap((usersType) => {
			return this._HttpClient.get<Omit<IResponse<UserData>, "data">>(
			  `${environment.baseUrl + usersType}`
			);
		  }),
		  map((response) => {
			if (response?.records) {
			  const phone = response.records.phone;
			  // Check if phone contains only numbers
			  const isValidPhone = /^\d+$/.test(phone!);
			  response.records.phone = isValidPhone ? phone : null;
			}
			return response; 
		  }),
		  tap((t) => {
			// Store user data
			this._authService.setUserData = t?.records ?? null;
		  })
		);
	  }
	  

	private _userTypeToUsersType(userType: UserType): UsersType {
		switch (userType) {
			case USER_TYPE.player:
				return USERS_TYPE.players;
			case USER_TYPE.academy:
				return USERS_TYPE.academies;
			case USER_TYPE.playground:
				return USERS_TYPE.playgrounds;
			case USER_TYPE.viewer:
				return USERS_TYPE.viewers;
			case USER_TYPE.scouting:
				return USERS_TYPE.scoutings;
			default:
				return userType;
		}
	}

	public requestPasswordForPhoneChange(): Observable<any> {
		return this._HttpClient.get(
			`${environment.baseUrl}email-addresses/send-password`
		);
	}
}
